import { createAsyncThunk } from '@reduxjs/toolkit'
import { CustomersRateService } from 'services/rateService'
const customersRateService = new CustomersRateService();

export const fetchCustomersRate = createAsyncThunk(
    'rates/fetchCustomersRate',
    async (param, { rejectWithValue }) => {
        try {
            const response = await customersRateService.getRate(param)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)
export const addRate = createAsyncThunk(
    'rates/addRate',
    async (param, { rejectWithValue }) => {
        try {
            const response = await customersRateService.addRate(param)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const updateRate = createAsyncThunk(
    'rates/updateRate',
    async (param, { rejectWithValue }) => {
        try {
            const response = await customersRateService.updateRate(param)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchCustomersMSARate = createAsyncThunk(
    'rates/fetchCustomersMSARate',
    async (param, { rejectWithValue }) => {
        try {
            const response = await customersRateService.getCustomersMSARate(param)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchTierNameById = createAsyncThunk(
    'rates/fetchTierNameById',
    async (param, { rejectWithValue }) => {
        try {
            const response = await customersRateService.getTierNameById(param)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const addTier = createAsyncThunk(
    'rates/addTier',
    async (param, { rejectWithValue }) => {
        try {
            const response = await customersRateService.addTier(param)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const updateTier = createAsyncThunk(
    'rates/updateTier',
    async (param, { rejectWithValue }) => {
        try {
            const response = await customersRateService.updateTier(param)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

