import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'component/dataTable/dataTable'
import { convertToDate, getDateWithTimeStamp } from 'utils/common'
import { TableHeader } from 'component/dataTable/tableHeader';
import '../userManagement/userAccount/UserAccount.scss'
import { fetchNotificationsForGeneral } from 'features/notification/notificationAction'

export const GeneralNotification = () => {
    const dispatch = useDispatch();

    const { user } = useSelector(
        (state) => state?.auth
    )

    const { broadcastMessages, success } = useSelector(
        (state) => state?.notifications
    )
 
    const [sorteData, setSortedData] = useState([]);
    const [updatedColumns, setUpdatedColumns] = useState([]);

    const fetchData = () => {
        dispatch(fetchNotificationsForGeneral({
            'topics': `dc-${user?.distributionCenterResponseVm?.dcName}-tenant-${user?.tenantName}-general`,
            receiverId: user?.login
        }))
    }

    useEffect(()=> {
        fetchData()
    }, [])

    useEffect(() => {
        if (success) {
            fetchData()
        }
    }, [success])

    const columns = [
        {
            accessor: "title",
            title: "Title",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.data?.title || '--',
            render: (item) => item?.data?.title || '--'
        },
        {
            accessor: "message",
            title: "Message",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.data?.body || '--',
            render: (item) => item?.data?.body || '--'
        },
        {
            accessor: 'timestamp',
            title: "Created Date",
            width: "2rem",
            Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(convertToDate(value), user) : '--',
            render: (item) => item?.timestamp ? getDateWithTimeStamp(convertToDate(item?.timestamp), user) : '--'
        }
    ]

    return (
        <div className='container'>
            <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Broadcast Messages' />
            <div className='content'>
                <DataTable columns={columns} data={broadcastMessages || []} isCollpsable={false} onSortChange={setSortedData} uniqueKey={'notificationId'} setUpdatedColumns={setUpdatedColumns} showPagination={false} />
            </div>
        </div>
    )
}
