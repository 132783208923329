import { environment } from "../environments/environment.jsx";
import { validateForm } from 'utils/formValidator'
import { customStrings } from 'properties/stringFile.jsx';

import moment from 'moment-timezone';

const mapAPIKey = environment.mapAPIKey;
const mapBoxAPIKey = environment.mapBoxToken;

const timeZoneList = {
    'AMERICA_TORONTO': 'America/Toronto',
    'ASIA_KOLKATA': 'Asia/Kolkata',
}

// Function to retrieve the map access key
export const getMapAccessKey = () => {
    return mapAPIKey
};

export const getMapBoxAccessKey = () => {
    return mapBoxAPIKey
};

export const getTime = (time) => {
    return time.split('.')[0]
}

export const getDate = (date, format = 'DD-MM-YYYY') => {
    try {
        const currentDate = new Date(date);
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = currentDate.getFullYear();
        if (format === 'DD-MM-YYYY') {
            return `${day}-${month}-${year}`;
        }
        else if (format === 'YYYY-MM-DD') {
            return `${year}-${month}-${day}`;
        }
        else {
            // Invalid format
            throw new Error('Invalid date format specified');
        }
    }
    catch (error) {
        return 'Invalid Date Format'
    }
}

export const covertToString = (data) => {
    const convertedString = data?.split("_")?.map(word => word?.charAt(0) + word?.slice(1)?.toLowerCase())?.join(" ")
    return convertedString
}

export const getFSAZones = (data) => {
    const zones = data && data.map((item) => {
        return { label: item?.fsaZoneName, value: item?.fsaZoneId };
    })
    return zones || []
}

export const formatDate = (dateString) => {
    const parts = dateString?.split('-');
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    return `${day}-${month}-${year}`;
}

export const getVehicles = (data, allowFilter = true) => {
    // Get all vehicles having no driver
    let vehicles = data
    if (allowFilter) {
        vehicles = data?.filter((vehicle) => vehicle?.driver === null)
    }
    vehicles = data?.map((item) => {
        return { label: item?.registrationNumber, value: item?.id, ...item };
    })
    vehicles?.sort((a, b) => a.label - b.label);
    return vehicles || []
}

export const getConstantValue = (data, value) => {
    const convertedString = data?.find(item => item?.value === value)
    return convertedString?.label
}

export const getFSAZonesForBatch = (data) => {
    const zones = data && data.map((item) => {
        return { label: item, value: item };
    })
    return zones || []
}

export const getOptionsForSelectField = (data) => {
    const customers = data && data?.map((item) => {
        return { label: item, value: item };
    })
    return customers || []
}

export const getCurrentDate = () => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = currentDate.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate
}

export const getUserTimeZone = (userTimezone) => {
    return timeZoneList?.[userTimezone]
}

function convertToUserTimezone(createDate, userTimezone, showTime) {
    if (!createDate) {
        return '--';
    }
    const date = new Date(createDate);
    const options = {
        timeZone: getUserTimeZone(userTimezone),
        hour12: true // Force 12-hour format
    };
    if (showTime) {
        // Show only time
        options.hour = 'numeric';
        options.minute = 'numeric';
    } else {
        // Show complete date
        options.year = 'numeric';
        options.month = 'numeric';
        options.day = 'numeric';
        options.hour = 'numeric';
        options.minute = 'numeric';
        options.second = 'numeric';
    }
    const userTime = new Intl.DateTimeFormat('default', options).format(date);
    return userTime.replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+) ([APMapm]+)$/, '$2-$1-$3 $4:$5:$6 $7');
}

export const getDateWithTimeStamp = (dateTime, user, showTime = false) => {
    try {
        let date = dateTime?.replace(/\[.*?\]/, '');
        const dcZone = user?.distributionCenterResponseVm?.timeZone || 'America/Toronto'
        const convertedTime = convertToUserTimezone(date, dcZone, showTime);
        return convertedTime
    }
    catch (error) {
        console.log('error', error)
        return '--'
    }
}

export const iconStyle = {
    color: '#5D48FF', // Change this to your desired color
    cursor: 'pointer'
};

export const getCurrentDateForFilter = (date) => {
    const parts = date ? date.split('-') : null;
    const today = parts ? new Date(parts[2], parts[1] - 1, parts[0]) : new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so we add 1 and pad with '0' if needed.
    const dd = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${yyyy}-${mm}-${dd}`;
    return formattedDate
}

export const getDriverList = (data) => {
    const drivers = data?.map((item) => {
        return { label: `${item?.user?.firstName} ${item?.user?.lastName}`, value: item?.id, ...item };
    })
    drivers?.sort((a, b) => a.label - b.label);
    return drivers || []
}

export const getDCObject = (dc, data) => {
    const selectedDC = data.find(item => item?.dcName === dc) || null
    return {
        address: selectedDC?.address,
        city: selectedDC?.city,
        dcLat: selectedDC?.lat || null,
        dcLon: selectedDC?.lon || null,
        dcName: selectedDC?.dcName,
        id: selectedDC?.id
    }
}

export const getUserRole = (user) => {
    return user?.authorities?.[0]
}

export const checkPermission = (user, widget) => {
    const rolePermissions = {
        ROLE_ADMIN: ['HOME', 'PACKAGES', 'LIVE TRACKING', 'PARCEL TRACKING', 'ORDER MANAGEMENT', 'BATCH', 'ROUTE', 'RETURN TERMINAL', 'TOTES', 'DELIVERY DASHBOARD', 'USER MANAGEMENT', 'DRIVER MANAGEMENT', 'VEHICLE MANAGEMENT', 'CLIENT MANAGEMENT', 'GRAFANA DASHBOARD', 'STREET_PERFECT_HELP', 'RETURN_TO_SENDER'],
        ROLE_SUPERVISOR: ['HOME', 'PACKAGES', 'LIVE TRACKING', 'PARCEL TRACKING', 'ORDER MANAGEMENT', 'BATCH', 'ROUTE', 'RETURN TERMINAL', 'DELIVERY DASHBOARD', 'DRIVER MANAGEMENT', 'VEHICLE MANAGEMENT', 'STREET_PERFECT_HELP', 'RETURN_TO_SENDER'],
        ROLE_CUSTOMER: ['HOME', 'PACKAGES', 'PARCEL TRACKING', 'ORDER MANAGEMENT', 'STREET_PERFECT_HELP'],
        ROLE_CSR: ['PACKAGES', 'LIVE TRACKING', 'PARCEL TRACKING', 'VEHICLE MANAGEMENT', 'STREET_PERFECT_HELP']
    };
    const userRole = getUserRole(user)

    // Check if the user has any of the required roles for the widget
    if (userRole && rolePermissions?.[userRole]) {
        return rolePermissions?.[userRole]?.includes(widget);
    }
    return false
};

export const getExportOptions = (user) => {
    const userRole = getUserRole(user)
    const roleExportOptions = {
        ROLE_ADMIN: ['CSV', 'EXCEL', 'PDF'],
        ROLE_SUPERVISOR: ['PDF'],
        ROLE_CUSTOMER: ['CSV', 'EXCEL', 'PDF'],
        ROLE_CSR: ['PDF'],
    };
    return roleExportOptions[userRole] || [];
}

export const getAPIFilterAsPerRole = (user, profile) => {
    const userRole = getUserRole(user)

    if (userRole?.includes('ROLE_CUSTOMER')) {
        return {
            'businessName': profile?.businessName
        }
    }
    return null
}

export const getGlobalFilter = (highPriority, sla) => {
    const filter = {}
    if (highPriority) {
        filter['highPriority'] = true
    }
    if (sla) {
        filter['sla'] = true
    }
    return filter
}

export const convertDateRangeToUTC = (startDateValue, endDateValue, currentFormat, applyFormatting = false, excludeEndDate = false) => {
    // Get the browser timezone
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { minDate, maxDate } = getMinMaxDate()

    //This applyFormatting is neededas we have different format in packages widget
    const startDateStr = startDateValue || (applyFormatting ? formatDate(minDate) : minDate)
    const endDateStr = endDateValue || (applyFormatting ? formatDate(maxDate) : maxDate)

    // Parse start and end dates into Moment.js objects
    const startDate = startDateStr && (currentFormat ? moment.tz(startDateStr, currentFormat, browserTimeZone) : moment.tz(startDateStr, browserTimeZone));
    const endDate = endDateStr && (currentFormat ? moment.tz(endDateStr, currentFormat, browserTimeZone) : moment.tz(endDateStr, browserTimeZone)).endOf('day');

    // Convert start and end dates to UTC
    const utcStartDate = startDate && startDate.clone().utc().format();
    const utcEndDate = endDate && endDate.clone().utc().format();

    // Return UTC start and end dates, exclude end date it passed
    return excludeEndDate ? { startDate: utcStartDate } : { startDate: utcStartDate, endDate: utcEndDate };
}

export const getUTCTime = (value) => {
    // Parse the date string using moment.js
    const parsedDateTime = moment(value?.$d);

    // Convert to UTC format
    const utcDateTime = parsedDateTime?.utc();

    // Format the UTC date string in ISO format
    const utcDateString = utcDateTime?.format();

    return utcDateString;
}

export const getUserDCOption = (user) => {
    const dc = user?.distributionCenterResponseVm
    return { label: dc?.dcName, value: dc?.dcName }
}

export const handleApplyFilter = (formValues, formInitialState, setFormErrors, setApplyFilter, setPage, applyFilter) => {
    const notRequiredFields = Object.keys(formInitialState); // Convert object keys to a list
    const errors = validateForm(formValues, notRequiredFields);
    if (Object.keys(errors)?.length) {
        setFormErrors(errors);
    } else {
        setFormErrors({});
        setApplyFilter(!applyFilter);
        setPage(0);
    }
}

export const convertTimeToSeconds = (time) => {
    var parts = time?.split(':')
    var hours = parseInt(parts[0])
    var minutes = parseInt(parts[1])
    var seconds = hours * 3600 + minutes * 60;
    return seconds
}

export const getPercent = (partialValue, totalValue) => {
    return (partialValue / totalValue) * 100;
};

const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2}(\.\d{1,3})?)?(Z|[\+\-]\d{2}:\d{2})$/;

export function isValidDateTime(value) {
    // Remove the [UTC] annotation
    const trimmedValue = value.replace(/\[.*\]/g, '');

    // Check if the datetime is valid
    return iso8601Regex.test(trimmedValue) && moment(trimmedValue, moment.ISO_8601, true).isValid();
}


export const convertToDate = (timestamp) => {
    const dateObj = new Date(timestamp.seconds * 1000 + Math.floor(timestamp.nanos / 1e6));
    return dateObj.toISOString();
}

export const getMinMaxDate = () => {
    const today = new Date();

    // Get min date (30 days ago)
    const minDateObj = new Date(today);
    minDateObj.setDate(today.getDate() - 30);
    const minDate = minDateObj.toISOString().split('T')[0];

    const maxDateObj = new Date(today);
    maxDateObj.setDate(today.getDate());
    const maxDate = maxDateObj.toISOString().split('T')[0];

    return { minDate, maxDate };
};


export const getAuditColumnValue = (data, value, user) => {
    if (data?.fieldName?.includes('Status')) {
        return  customStrings[value] || covertToString(value)
    }
    if (isValidDateTime(value)) {
        return getDateWithTimeStamp(value, user)
    }
    return value || '--'
}