import { createSlice } from "@reduxjs/toolkit";
import { fetchNotifications, markNotificationAsRead, subscribeToNotifications, fetchNotificationAudit, sendNotification, fetchNotificationsForGeneral } from './notificationAction'
import { logout } from 'features/auth/authAction'

const initialState = {
  messages: [],
  loading: false,
  error: null,
  success: false,
  readSuccess: false,
  broadcastMessages: [],
  notificationAudit: [],
  notificationAuditCount: 0
}

const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    addFcmMessage(state, action) {
      const fcmMessage = {
        ...action.payload
      };
      state.messages.unshift(fcmMessage);
    },

    clearMessages(state) {
      state.messages = [];
    },
    clearSuccessMessage(state) {
      state.readSuccess = false
      state.success = false
    }
  },
  extraReducers: (builder) => {
    builder
      // notification Slice
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        const filteredMessages =  action?.payload?.data?.filter(message => !message?.disposeBy?.includes(action?.payload?.userId));
        state.loading = false;
        state.error = null;
        state.messages = filteredMessages
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })

      .addCase(fetchNotificationAudit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotificationAudit.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null; 
        state.notificationAudit = action?.payload?.audit
        state.notificationAuditCount = action?.payload?.totalCount
      })
      .addCase(fetchNotificationAudit.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })

      .addCase(fetchNotificationsForGeneral.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotificationsForGeneral.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.broadcastMessages = action?.payload
      })
      .addCase(fetchNotificationsForGeneral.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })

      .addCase(subscribeToNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(subscribeToNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(subscribeToNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })

      .addCase(sendNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true
      })
      .addCase(sendNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })

      //Mark notification read
      .addCase(markNotificationAsRead.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(markNotificationAsRead.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.readSuccess = true
      })
      .addCase(markNotificationAsRead.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })

      .addCase(logout.fulfilled, (state) => {
        return initialState;
      })

  },
});

export const { addFcmMessage, clearMessages, clearSuccessMessage } = notificationSlice.actions;

export default notificationSlice.reducer;
