import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'component/dataTable/dataTable'
import { covertToString, getDate } from 'utils/common'
import { TableHeader } from 'component/dataTable/tableHeader';
import '../userManagement/userAccount/UserAccount.scss'
import { fetchNotificationAudit } from 'features/notification/notificationAction'
import { TableFilter } from 'component/dataTable/tableFilter';
import useFieldChange from 'hooks/useFieldChange';
import { handleApplyFilter, getCurrentDateForFilter, getMinMaxDate, convertDateRangeToUTC } from 'utils/common';

const date = getCurrentDateForFilter()

const formInitialState = {
    topic: '',
    startDate: date,
    endDate: date,
}

export const NotificationAudit = () => {
    const dispatch = useDispatch();

    const { isMobile } = useSelector(
        (state) => state?.viewport
    )

    const { notificationAudit, notificationAuditCount } = useSelector(
        (state) => state?.notifications
    )

    const [sorteData, setSortedData] = useState([]);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const [applyFilter, setApplyFilter] = useState(false);

    useEffect(() => {
        const { startDate, endDate } = formValues
        const dateFilter = convertDateRangeToUTC(startDate, endDate, 'YYYY-MM-DD', false)

        const filter = {
            page: page,
            size: rowsPerPage,
            ...dateFilter,
            topic: formValues?.topic
        };

        dispatch(fetchNotificationAudit({ ...filter }))
    }, [page, rowsPerPage, applyFilter])


    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handleResetFilter = () => {
        setFormValues({
            ...formInitialState,
            startDate: '',
            endDate: ''
        })
        setFormErrors({})
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const handleApplyFilterClick = () => {
        handleApplyFilter(formValues, formInitialState, setFormErrors, setApplyFilter, setPage, applyFilter);
    }

    const columns = [
        {
            accessor: "topic",
            title: "Topic",
            width: "3rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.topic || '--'
        },
        {
            accessor: "event",
            title: "Event",
            width: "3rem",
            Cell: ({ cell: { value } }) => value ? covertToString(value) : '--',
            render: (item) => item?.event ? covertToString(item?.event) : '--'
        },
        {
            accessor: "senderId",
            title: "Sender",
            width: "3rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.senderId || '--'
        },
        {
            accessor: "title",
            title: "Title",
            width: "3rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.title || '--'
        },
        {
            accessor: "body",
            title: "Content",
            width: "3rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.body || '--'
        },
        {
            accessor: "type",
            title: "Type",
            width: "3rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.type || '--'
        },
        {
            accessor: 'createDate',
            title: "Created Date",
            width: "2rem",
            Cell: ({ cell: { value } }) => value ? getDate(value) : '--',
            render: (item) => item?.createDate ? getDate(item?.createDate) : '--'
        }
    ]

    const tableFilterProps = {
        fields: [
            {
                label: 'Topic',
                value: formValues?.topic,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '12rem',
                name: 'topic',
                error: formError?.topic,
                placeholder: 'Topic'
            },
            {
                label: 'Start Date',
                value: formValues?.startDate,
                handleFieldChange: handleFieldChange,
                type: 'date',
                width: isMobile ? '9rem' : '11rem',
                name: 'startDate',
                placeholder: 'DD-MM-YYYY',
                error: formError?.startDate,
                ...(formValues?.startDate ? {
                    min: getMinMaxDate()?.minDate,
                    max: getMinMaxDate()?.maxDate
                } : {}),
            },
            {
                label: 'End Date',
                value: formValues?.endDate,
                handleFieldChange: handleFieldChange,
                type: 'date',
                width: isMobile ? '9rem' : '11rem',
                name: 'endDate',
                placeholder: 'DD-MM-YYYY',
                error: formError?.endDate,
                ...(formValues?.endDate ? {
                    min: getMinMaxDate()?.minDate,
                    max: getMinMaxDate()?.maxDate
                } : {})

            }
        ],
        buttons: [
            {
                label: 'Apply',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'black',
                onClick: handleApplyFilterClick
            },
            {
                label: 'Reset',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'blue',
                onClick: handleResetFilter
            }
        ],
        margin: '0px',
        padding: '10px',
        borderRadius: '10px',
    }

    return (
        <div className='container'>
            <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Notification Audit' />
            <TableFilter {...tableFilterProps} />
            <div className='content'>
                <DataTable columns={columns} data={notificationAudit || []} showPagination={notificationAudit?.length ? true : false} onPageChange={handlePageChange} page={page} totalRowsCount={notificationAuditCount} onSortChange={setSortedData} uniqueKey={'messageId'} rowKey={'messageId'} setUpdatedColumns={setUpdatedColumns} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} />
            </div>
        </div>
    )
}
