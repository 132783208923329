import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'component/common/Layout/Layout';
import { Loader } from 'component/common/Loader/Loader'
import { PrimaryButton } from 'component/common/Button/Button.jsx'
import { TabWrapper, Tab } from 'component/common/Tab/Tab';
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import '../userManagement/userAccount/UserAccount.scss'
import { clearSuccessMessage } from 'features/notification/notificationSlice'
import { GeneralNotification } from './GeneralNotification'
import { NotificationAudit } from './NotificationAudit'
import { AddNotification } from './AddNotification';

const tabEnum = {
    GENERAL_NOTIFICATION: 'GENERAL_NOTIFICATION',
    AUDIT: 'AUDIT'
}

const tabs = [
    {
        value: tabEnum?.GENERAL_NOTIFICATION,
        label: 'General Notification'
    },
    {
        value: tabEnum?.AUDIT,
        label: 'Audit'
    }
]

export const Notification = () => {
    const dispatch = useDispatch();
    const selectedTabRef = useRef();

    const { error, loading, success } = useSelector(
        (state) => state?.notifications
    )

    const [modalProps, setModalProps] = useState(null)
    const [addModalOpen, setAddModalOpen] = useState(false)
    const [selectedTab, setSelectedTab] = useState(tabEnum?.GENERAL_NOTIFICATION);

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
    }, [error]);

    useEffect(() => {
        if (success) {
            setModalProps(
                {
                    title: 'Success',
                    message: 'Notification sent successfully',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                handleAddModalClick()
                setModalProps(null)
                dispatch(clearSuccessMessage())
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [success]);



    const handleTabChange = (event, value) => {
        selectedTabRef.current = value
        setSelectedTab(value)
    }

    const handleAddModalClick = () => {
        setAddModalOpen(!addModalOpen)
    }

    return (
        <Layout headerTitle={'Notifications'} showBackArrow={false} action={<div><PrimaryButton type="button" variant='primary' label={'Send Notification'} onClick={() => setAddModalOpen(true)} height={'30px'} /></div>}
        >
            <TabWrapper>
                <Tab tabs={tabs} handleChange={handleTabChange} value={selectedTab} />
            </TabWrapper>
            {selectedTabRef?.current === tabEnum?.AUDIT  ?  <NotificationAudit /> : <GeneralNotification /> }
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
            {addModalOpen ? <AddNotification isModelOpen={addModalOpen} handleClose={handleAddModalClick} /> : null}
        </Layout>
    )
}
