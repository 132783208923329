import { createAsyncThunk } from '@reduxjs/toolkit'
import { NotificationServices } from 'services/notificationServices'
const notificationServices = new NotificationServices();

export const fetchNotifications = createAsyncThunk(
    'notification/fetchNotifications',
    async (formData, { rejectWithValue }) => {
        try {
            let response = await notificationServices.fetchNotifications(formData)
            return { data: response?.data, userId: formData?.receiverId }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchNotificationsForGeneral = createAsyncThunk(
    'notification/fetchNotificationsForGeneral',
    async (formData, { rejectWithValue }) => {
        try {
            let response = await notificationServices.fetchNotifications(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchNotificationAudit = createAsyncThunk(
    'notification/fetchNotificationAudit',
    async (formData, { rejectWithValue }) => {
        try {
            let response = await notificationServices.fetchNotificationAudit(formData)
            return { audit: response?.data, totalCount: response.headers.get('X-Total-Count') }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)


export const sendNotification = createAsyncThunk(
    'notification/sendNotification',
    async (formData, { rejectWithValue }) => {
        try {
            let response = await notificationServices.sendNotification(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const subscribeToNotifications = createAsyncThunk(
    'notification/subscribeToNotifications',
    async (formData, { rejectWithValue }) => {
        try {
            let response = await notificationServices.subscribeToNotifications(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const markNotificationAsRead = createAsyncThunk(
    'notification/markNotificationAsRead',
    async (formData, { rejectWithValue }) => {
        try {
            let response = await notificationServices.markNotificationAsRead(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)